import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
	connect() {
		setTimeout(() => {
			this.element.classList.add('slide-up');
		}, 5000);

		// Add event listener for the end of the animation
		this.element.addEventListener('animationend', () => {
			this.element.style.display = 'none';
		});
	}

}
import { Controller } from "stimulus";

export default class extends Controller {
	static targets = ["carouselItem"];

	connect() {
		// Get all carousel items
		this.items = this.carouselItemTargets;

		// Duplicate each item for smooth sliding effect (optional if you have fewer products)
		this.items.forEach((el, index) => {
			const next = this.items[index + 1] || this.items[0];  // Find the next item or loop back to the start
			const nextNext = this.items[index + 2] || this.items[0];

			// Clone next two products and append them inside the current item
			el.querySelector('.row').appendChild(next.querySelector('.col-md-4').cloneNode(true));
			el.querySelector('.row').appendChild(nextNext.querySelector('.col-md-4').cloneNode(true));
		});
	}
}

import { Controller } from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
	async confirm(event) {
		event.preventDefault(); // Prevent form submission

		// Show spinner while clearing cache
		const spinner = Swal.fire({
			title: 'Brisanje predpomnilnika...',
			text: 'Prosimo, počakajte, medtem ko se predpomnilnik briše.',
			icon: 'info',
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			}
		});

		// Proceed with the cache clearing process
		await this.clearCache();
	}

	async clearCache() {
		const url = this.element.action; // Form action URL
		const formData = new FormData(this.element); // Capture form data (including CSRF token)

		try {
			// Send the AJAX request to clear the cache
			const response = await fetch(url, {
				method: 'POST',
				body: formData
			});

			const data = await response.json(); // Parse the response

			// Close the spinner
			Swal.close();

			// Show success message after cache is cleared
			await Swal.fire({
				title: 'Predpomnilnik izbrisan!',
				text: data.message,
				icon: 'success',
				confirmButtonText: 'OK'
			});

		} catch (error) {
			// Close the spinner in case of error
			Swal.close();

			// Show error message if something goes wrong
			await Swal.fire({
				title: 'Error',
				text: 'Something went wrong while clearing the cache.',
				icon: 'error',
				confirmButtonText: 'OK'
			});
		}
	}
}

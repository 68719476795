// assets/controllers/login_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['email', 'password'];

	validate(event) {
		this.clearErrors();
		let isValid = true;

		// Validate email
		if (!this.emailTarget.value.trim()) {
			this.showError('E-naslov je obvezen.', this.emailTarget);
			isValid = false;
		} else if (!this.validateEmail(this.emailTarget.value.trim())) {
			this.showError('E-naslov ni veljaven.', this.emailTarget);
			isValid = false;
		}

		// Validate password
		if (!this.passwordTarget.value.trim()) {
			this.showError('Geslo je obvezno.', this.passwordTarget);
			isValid = false;
		}

		// Prevent form submission if not valid
		if (!isValid) {
			event.preventDefault();
		}
	}

	// Helper to validate email format
	validateEmail(email) {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(String(email).toLowerCase());
	}

	// Helper to display an error message
	showError(message, element) {
		const parentElement = element.closest('.form-outline') || element.parentElement;

		if (parentElement && !parentElement.querySelector('.invalid-feedback')) {
			const error = document.createElement('div');
			error.classList.add('invalid-feedback', 'd-block');
			error.innerText = message;
			parentElement.appendChild(error);
		}
	}

	// Clear any existing errors
	clearErrors() {
		this.element.querySelectorAll('.invalid-feedback').forEach(error => error.remove());
	}
}

import { Controller } from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
	changeStatus(event) {
		const newStatusId = event.target.value; // Get the selected status ID
		const orderId = this.element.dataset.orderId; // Assuming you pass the order ID as data attribute

		// Show a SweetAlert to notify the user
		Swal.fire({
			title: 'Ali ste prepričani?',
			text: 'Status naročila bo spremenjen!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Da, spremeni!',
			cancelButtonText: 'Prekliči'
		}).then((result) => {
			if (result.isConfirmed) {
				// If confirmed, send the AJAX request to update the order status
				this.updateOrderStatus(orderId, newStatusId);
			}
		});
	}

	async updateOrderStatus(orderId, newStatusId) {
		try {
			const csrfToken = this.element.dataset.csrfToken; // If using data attribute method

			const response = await fetch(`/admin/order/${orderId}/status`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken // Include CSRF token here
				},
				body: JSON.stringify({ status: newStatusId }) // Send the new status
			});

			const result = await response.json();

			if (response.ok) {
				// Show success SweetAlert
				Swal.fire({
					title: 'Status posodobljen!',
					text: 'Status naročila je bil uspešno posodobljen.',
					icon: 'success',
					confirmButtonText: 'V redu'
				}).then(() => {
					// Refresh the page after the user clicks "OK"
					window.location.reload();
				});
			} else {
				// Show error if something went wrong
				Swal.fire({
					title: 'Napaka!',
					text: result.message || 'Prišlo je do napake pri posodabljanju statusa.',
					icon: 'error',
					confirmButtonText: 'V redu'
				});
			}
		} catch (error) {
			// Show a general error SweetAlert in case of failure
			Swal.fire({
				title: 'Napaka!',
				text: 'Prišlo je do napake pri povezavi s strežnikom.',
				icon: 'error',
				confirmButtonText: 'V redu'
			});
		}
	}

}

import {Controller} from "@hotwired/stimulus";
import modal from "bootstrap/js/src/modal";
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
	static targets = ['modal'];
	static values = {
		formUrl: String,
	}
	modal = null;

	/*async open(event){
		const modalEle = this.modalTarget;
		const modalTitle = modalEle.querySelectorAll('.modal-title');
		const modalBody = modalEle.querySelectorAll('.modal-body');
		modalTitle[0].innerHTML = "Vaša Košarica";

		//preberi vse izdelke iz košarice

		const response = await fetch(`${event.params.url}`);
		const responseText = await response.json();
		const bodyBasketHolder = await responseText;
console.log(bodyBasketHolder.productName);
		modalBody[0].innerHTML = bodyBasketHolder.productName;



		modal.getOrCreateInstance(modalEle).show();
	}*/

	connect() {
		useDispatch(this);
	}

	async openModal(event) {

		this.modal = new modal(this.modalTarget);
		this.modal.show();
	}

}
import { Controller } from '@hotwired/stimulus';


export default class extends Controller {
	connect() {
	}

	async deleteProduct({params: {id, url}}) {
		event.preventDefault();
		const elementid = 'product' + id;
		const productid = document.getElementById(elementid);
		const orderSummary = document.getElementById('order-summary');
		const numOfProducts = document.getElementById('number_of_products');
		const basket = document.getElementById('basket');

		const response = await fetch(url);
		const responseText = await response.json();

		productid.remove();
		orderSummary.reload();
		numOfProducts.reload();
		basket.reload();
	}

	async downQty({params: {id, url}}) {
		event.preventDefault();
		const elementid = 'product' + id;
		const productid = document.getElementById(elementid);
		const qtyElement = document.getElementById('qty' + id);
		const sum = document.getElementById('sum' + id);
		const price = document.getElementById('price' + id);

		const response = await fetch(url);
		const responseText = await response.json();

		/*if (responseText.qty === 0) {
			productid.remove();
		}else{
			price.innerText = responseText.price + " €";
			sum.innerText = responseText.sumprice + " €";
			qtyElement.value = responseText.qty;
		}*/

		// Refresh the page
		window.location.reload();
	}

	async upQty({params: {id, url}}) {
		event.preventDefault();
		const elementid = 'product' + id;
		const qtyElement = document.getElementById('qty' + id);
		const sum = document.getElementById('sum' + id);
		const price = document.getElementById('price' + id);

		const response = await fetch(url);
		const responseText = await response.json();

		//qtyElement.value = responseText.qty;
		//price.innerText = responseText.price + " €";
		//sum.innerText = responseText.sumprice + " €";

		// Refresh the page
		window.location.reload();
	}

}

import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['products', 'categories', 'attributeElements', 'position'];

	connect() {
		this.toggleFields();
	}

	toggleFields() {
		const positionValue = this.positionTarget.value;
		console.log(this.positionTarget);
		this.productsTarget.style.display = positionValue === 'product' ? 'block' : 'none';
		this.categoriesTarget.style.display = positionValue === 'category' ? 'block' : 'none';
		this.attributeElementsTarget.style.display = positionValue === 'attribute' ? 'block' : 'none';
	}

	change(event) {
		this.toggleFields();
	}
}
import { Controller } from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
	static targets = ["paymentholder", "shippmentholder"];

	savePaymentAddress(event) {
		event.preventDefault();
		this.saveAddress(this.paymentholderTarget, 'choosepaymentaddress');
	}

	saveShippmentAddress(event) {
		event.preventDefault();
		this.saveAddress(this.shippmentholderTarget, 'choosedeliveryaddress');
	}

	saveAddress(holder, excludeFieldId) {
		const fields = holder.querySelectorAll('input, select');
		let addressData = {};

		fields.forEach(field => {
			if (field.id !== excludeFieldId) {
				addressData[field.name] = field.value;
			}
		});

		fetch('/save-address', {
			method: 'POST',
			body: JSON.stringify(addressData),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data.message) {
					Swal.fire({
						title: 'Success',
						text: data.message,
						icon: 'success',
						confirmButtonText: 'OK'
					});
					if (data.addresses) {
						// Optionally, update the address options if needed
					}
				} else {
					Swal.fire({
						title: 'Error',
						text: 'Failed to save address.',
						icon: 'error',
						confirmButtonText: 'OK'
					});
				}
			})
			.catch(error => {
				console.error('Error:', error);
				Swal.fire({
					title: 'Error',
					text: 'An error occurred while saving the address.',
					icon: 'error',
					confirmButtonText: 'OK'
				});
			});
	}
}
// controllers/gafinal_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
    static values = {
        trackingId: String
    };

    connect() {
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        this.isAnalyticsInitialized = false;
        this.initialLoadTracked = false;
        this.engagementLogged = false;
        this.lastTrackedPage = '';

        this.initializeAnalytics();
        document.addEventListener('turbo:load', this.trackPage.bind(this));
        this.trackPage(); // Track the initial load
    }

    initializeAnalytics() {
        if (!this.isAnalyticsInitialized) {
            console.log("Initializing Google Analytics");
            this.gtag('js', new Date());
            this.gtag('config', this.trackingIdValue, {
                'anonymize_ip': true,
                'send_page_view': false // Prevent duplicate initial page view
            });
            this.isAnalyticsInitialized = true;
        }
    }

    gtag() {
        window.dataLayer.push(arguments);
        console.log('gtag arguments', arguments); // Debugging logs for Google Analytics
    }

    getCurrentFullUrl() {
        return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    }

    trackPage() {
        const url = window.location.pathname;
        const title = document.title;

        if (this.lastTrackedPage !== url) {
            this.lastTrackedPage = url;
            this.trackPageView(url, title);
            this.engagementLogged = false; // Reset engagement log for new page load

            // Track user engagement after ensuring the page view is logged
            setTimeout(() => {
                this.trackUserEngagement(performance.now());
            }, 100);
        }
    }

    trackPageView(url, title) {
        console.log(`Tracking page view for ${url}`);
        this.gtag('event', 'page_view', {
            'event_category': 'page_view',
            'event_label': title,
            'page_path': url,
            'page_title': title,
            'page_location': this.getCurrentFullUrl()
        });
    }

    trackUserEngagement(duration) {
        if (!this.engagementLogged) {
            console.log(`Tracking user engagement for ${duration} milliseconds`);
            this.gtag('event', 'user_engagement', {
                'engagement_time_msec': duration,
                'page_location': this.getCurrentFullUrl()
            });
            this.engagementLogged = true;
        }
    }
}
import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
	static targets = ['vari']

	deleteVariant(event){
		var data = {
			"_token": event.currentTarget.dataset.tokeId,
		}
		var headers = {
			"Content-Type": "application/json",
			"Access-Control-Origin": "*"
		}
		const deleteVarElement=event.currentTarget.closest('.accordion-item');
		fetch(event.currentTarget.dataset.urlId, {
			method: "POST",
			headers: headers,
			body:  JSON.stringify(data)
		})
			.then(function(response){
				deleteVarElement.remove();
				return response.json();
			})
			.then(function(data){
				console.log(data)
			});
	}

	updateVariant(event){
		event.preventDefault();
		const submitTarget = event.currentTarget;
		const currentForm=submitTarget.closest('form');
		const formAction=currentForm.action;
		const formData = Object.fromEntries(new FormData(currentForm).entries());

		var data = formData;
		var headers = {
			"Content-Type": "application/json",
			"Access-Control-Origin": "*"
		}
		fetch(formAction, {
			method: "POST",
			headers: headers,
			body:  JSON.stringify(data)
		})
			.then(function(response){
				if(response.ok){
					console.log('ok update');
				}else {
					console.log(response);
				}
				return response.json();
			})
			.then(function(data){
				console.log(data);
			});
	}

	getFormVariantImage(event){

		var headers = {
			"Content-Type": "application/json",
			"Access-Control-Origin": "*"
		}
		var theDiv = document.getElementById(event.currentTarget.dataset.divId);
		fetch(event.currentTarget.dataset.pviurlId, {
			method: "GET",
			headers: headers
		})
			.then(function(response){
				if(response.ok){
					console.log('ok update');
				}else {
					console.log(response);
				}
				//
				return response.text();
			})
			.then(function(data){
				theDiv.innerHTML = data;
				console.log(data);
			});
	}

}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	async performSearch(event) {
		event.preventDefault();
		const form = event.target;
		const url = form.action;

		const response = await fetch(`${url}?${new URLSearchParams(new FormData(form)).toString()}`, {
			method: 'GET',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
			},
		});

		if (response.ok) {
			const html = await response.text();
			document.getElementById('search-results').innerHTML = html;
		}
	}
}

   // assets/controllers/close_promobox_controller.js

   import { Controller } from "@hotwired/stimulus";

   export default class extends Controller {
     static targets = ["promobox"];

     close() {
       this.promoboxTarget.remove();
     }
   }
// assets/controllers/password_strength_controller.js
import { Controller } from '@hotwired/stimulus';
import zxcvbn from 'zxcvbn';

export default class extends Controller {
    static targets = ['password', 'confirmPassword', 'submitButton', 'meter', 'feedback', 'togglePassword', 'toggleConfirmPassword']

    connect() {
        this.updateStrength = this.updateStrength.bind(this);
        this.passwordTarget.addEventListener('input', this.updateStrength);

        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
        this.togglePasswordTarget.addEventListener('click', this.togglePasswordVisibility);

        this.toggleConfirmPasswordVisibility = this.toggleConfirmPasswordVisibility.bind(this);
        this.toggleConfirmPasswordTarget.addEventListener('click', this.toggleConfirmPasswordVisibility);

        this.checkPasswords = this.checkPasswords.bind(this);
        this.passwordTarget.addEventListener('input', this.checkPasswords);
        this.confirmPasswordTarget.addEventListener('input', this.checkPasswords);
    }

    disconnect() {
        this.passwordTarget.removeEventListener('input', this.updateStrength);
        this.togglePasswordTarget.removeEventListener('click', this.togglePasswordVisibility);
        this.toggleConfirmPasswordTarget.removeEventListener('click', this.toggleConfirmPasswordVisibility);
        this.passwordTarget.removeEventListener('input', this.checkPasswords);
        this.confirmPasswordTarget.removeEventListener('input', this.checkPasswords);
    }

    updateStrength() {
        const result = zxcvbn(this.passwordTarget.value);
        const score = result.score;

        // Update the feedback text and meter background color
        const strengthTexts = ['Zelo šibko', 'Šibko', 'Sprejemljivo', 'Močno', 'Zelo močno'];
        const feedbackClasses = ['bg-danger', 'bg-warning', 'bg-info', 'bg-primary', 'bg-success'];

        this.feedbackTarget.textContent = strengthTexts[score];

        // Update Bootstrap class for the meter
        this.meterTarget.className = `progress-bar ${feedbackClasses[score]}`;
        this.meterTarget.style.width = `${(score + 1) * 20}%`;
    }

    togglePasswordVisibility() {
        const type = this.passwordTarget.getAttribute('type');
        this.passwordTarget.setAttribute('type', type === 'password' ? 'text' : 'password');
        this.togglePasswordTarget.querySelector('i').classList.toggle('fa-eye');
        this.togglePasswordTarget.querySelector('i').classList.toggle('fa-eye-slash');
    }

    toggleConfirmPasswordVisibility() {
        const type = this.confirmPasswordTarget.getAttribute('type');
        this.confirmPasswordTarget.setAttribute('type', type === 'password' ? 'text' : 'password');
        this.toggleConfirmPasswordTarget.querySelector('i').classList.toggle('fa-eye');
        this.toggleConfirmPasswordTarget.querySelector('i').classList.toggle('fa-eye-slash');
    }

    checkPasswords() {
        this.validatePasswords();
    }

    validatePasswords() {
        const password = this.passwordTarget.value;
        const confirmPassword = this.confirmPasswordTarget.value;
        const errorElement = document.getElementById('password-error');

        // Clear any previous messages
        errorElement.style.display = 'none';
        errorElement.innerHTML = '';

        let valid = true;

        // Regex patterns for validation
        const numberPattern = /\d/;
        const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
        const lowerCasePattern = /[a-z]/;
        const upperCasePattern = /[A-Z]/;

        // Check password length
        if (password.length < 12) {
            errorElement.innerHTML = 'Geslo mora imeti vsaj 12 znakov.';
            valid = false;
        }

        // Check if password contains a number
        if (!numberPattern.test(password)) {
            errorElement.innerHTML += (valid ? '' : '<br>') + 'Geslo mora vsebovati vsaj eno številko.';
            valid = false;
        }

        // Check if password contains a special character
        if (!specialCharPattern.test(password)) {
            errorElement.innerHTML += (valid ? '' : '<br>') + 'Geslo mora vsebovati vsaj en poseben znak (!@#$%^&*(),.?":{}|<>).';
            valid = false;
        }

        // Check if password contains a lowercase letter
        if (!lowerCasePattern.test(password)) {
            errorElement.innerHTML += (valid ? '' : '<br>') + 'Geslo mora vsebovati vsaj eno malo črko.';
            valid = false;
        }

        // Check if password contains an uppercase letter
        if (!upperCasePattern.test(password)) {
            errorElement.innerHTML += (valid ? '' : '<br>') + 'Geslo mora vsebovati vsaj eno veliko črko.';
            valid = false;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            errorElement.innerHTML += (valid ? '' : '<br>') + 'Gesli se ne ujemata.';
            valid = false;
        }

        // Display error message if validation fails
        if (!valid) {
            errorElement.style.display = 'block';
            this.submitButtonTarget.disabled = true;
        } else {
            this.submitButtonTarget.disabled = false;
        }
    }
}
import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        this.element.textContent = 'Hello Stimulus! Edit me in assets/controllers/hello_controller.js';


        var itemTable = document.getElementById('ld_121739');
        itemTable.querySelectorAll('tr.listItem').forEach(tr => {
                   const td = tr.lastElementChild;
                   td.appendChild(document.createElement('td'));
                   console.log(td);
        })
        console.log(itemTable.classList.add());
    }
}

// assets/controllers/tom_select_controller.js
import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
	connect() {
		new TomSelect(this.element, {
			plugins: ['remove_button'], // Optional: add plugins as needed
			create: false,
			sortField: {
				field: "text",
				direction: "asc"
			},
			placeholder: 'Izberite možnost', // Change this to your preferred placeholder
			allowClear: true,
		});
	}
}

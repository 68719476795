import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        // Ensure Cookiebot is properly initialized when the controller connects
        this.waitForCookiebotInitialization();

        // Listen for Turbo page transitions
        document.addEventListener("turbo:before-visit", this.handleTurboBeforeVisit.bind(this));
        document.addEventListener("turbo:load", this.reinitializeCookiebot.bind(this));

        // Apply custom styles to the Cookiebot widget
        this.applyCookiebotStyles();
    }

    waitForCookiebotInitialization() {
        // Check if Cookiebot is already initialized
        if (window.CookieConsent) {
            this.checkAndLoadCookiebot();
        } else {
            // Wait for the Cookiebot initialization event
            window.addEventListener('CookieConsentScriptLoaded', () => {
                this.checkAndLoadCookiebot();
            });
        }
    }

    checkAndLoadCookiebot() {
        // Use the Cookiebot API to verify if the user has given consent for third-party cookies
        if (this.hasThirdPartyConsent()) {
            this.reloadCookiebotScript();
        } else {
            console.log('Third-party cookie consent not given, not loading Cookiebot.');
        }
    }

    handleTurboBeforeVisit() {
        // Remove the Cookiebot script before navigating to a new page
        const existingScript = document.getElementById('Cookiebot');
        if (existingScript) {
            existingScript.remove();
            console.log('Removed existing Cookiebot script before page change');
        }
    }

    reloadCookiebotScript() {
        if (!document.getElementById('Cookiebot')) {
            const cookiebotScript = document.createElement('script');
            cookiebotScript.id = 'Cookiebot';
            cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
            cookiebotScript.setAttribute('data-cbid', 'c2225c9a-fe80-472a-b386-ab38c3470e09');
            cookiebotScript.setAttribute('data-blockingmode', 'auto');
            cookiebotScript.type = 'text/javascript';
            cookiebotScript.async = true;
            document.head.appendChild(cookiebotScript);
            console.log('Loaded Cookiebot script:', cookiebotScript);
        }
    }

    reinitializeCookiebot() {
        console.log("Reinitializing Cookiebot after Turbo page load");
        // Trigger a renew on Cookiebot to re-evaluate consent and ensure the widget is displayed
        Cookiebot.init();
        Cookiebot.widget.loaded = false;
        Cookiebot.initWidget();
        this.ensureCookiebotWidgetIsVisible();
    }

    ensureCookiebotWidgetIsVisible() {
        // Ensure that the Cookiebot widget icon is visible after consent
        if (window.CookieConsent && typeof window.CookieConsent.showWidget === 'function') {
            window.CookieConsent.showWidget();
        }
    }

    applyCookiebotStyles() {
        const style = document.createElement('style');
        style.innerHTML = `
            #CookiebotWidget * {
                color: #141414 !important; 
            }
            #CookiebotWidget-btn-change {
                color: #fff !important; 
            }
        `;
        document.head.appendChild(style);
        console.log('Applied custom styles to Cookiebot widget');
    }

    hasThirdPartyConsent() {
        // Check if the user has given consent to marketing (third-party) cookies using Cookiebot's API
        return window.CookieConsent && typeof window.CookieConsent.getConsentFor === 'function' &&
          window.CookieConsent.getConsentFor('marketing') === true;
    }

    disconnect() {
        // Clean up event listeners when the controller is disconnected
        document.removeEventListener("turbo:before-visit", this.handleTurboBeforeVisit.bind(this));
        document.removeEventListener("turbo:load", this.reinitializeCookiebot.bind(this));
    }
}

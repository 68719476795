import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ['count', 'varid', 'prodid'];

	static products = {
		id: Number,
		varid: Number,
		qty: Number
	}

	connect() {
		this.numberOfProducts = 1;
		this.countTarget.value = this.numberOfProducts;
	}

	increment() {
		this.numberOfProducts++;
		this.countTarget.value = this.numberOfProducts;
	}

	reduce() {
		if (this.numberOfProducts > 1) {
			this.numberOfProducts--;
			this.countTarget.value = this.numberOfProducts;
		}
	}

	changevariant(event) {
		const variantid = event.target.value;
		const showBox = 'variacije' + variantid;

		var divsToHide = document.getElementsByClassName("variacije");
		for (var i = 0; i < divsToHide.length; i++) {
			if (divsToHide[i].classList.contains('d-inherit')) {
				divsToHide[i].classList.remove('d-inherit');
				divsToHide[i].classList.add('d-none');
			} else {
				divsToHide[i].classList.remove('d-none');
				divsToHide[i].classList.add('d-inherit');
				this.varidTarget.value = variantid;
			}
		}
	}

	async addToBasket(event) {
		let qty = document.getElementById('qty' + event.params.varid);
		let basketHolder = document.getElementById('number_of_products');
		let userBasketHolder = document.getElementById('basket');

		const params = new URLSearchParams({
			prodId: event.params.id,
			varId: event.params.varid,
			qty: qty.value,
		});

		var button = event.currentTarget.childNodes.item(3).classList;
		button.remove('d-none');

		const response = await fetch(`${event.params.url}?${params.toString()}`);
		const responseText = await response.json();
		const bodyBasketHolder = await responseText.products;

		userBasketHolder.reload();

		basketHolder.innerHTML = bodyBasketHolder;
		button.add('d-none');

	}

	async remove(event) {
		let modalSpinner = document.getElementById('modal-spinner');
		let userBasketHolder = document.getElementById('basket');
		let numOfProd = document.getElementById('number_of_products');
		modalSpinner.classList.remove('d-none');

		const response = await fetch(`${event.params.url}`);
		const responseText = await response.json();

		await numOfProd.reload();
		await userBasketHolder.reload();

		modalSpinner.classList.add('d-none');
	}

	async addToBasketProductPage(event) {
		let qty = this.countTarget.value;
		let varId = this.varidTarget.value;
		let prodId = this.prodidTarget.value;

		let basketHolder = document.getElementById('number_of_products');
		let userBasketHolder = document.getElementById('basket');

		const params = new URLSearchParams({
			prodId: prodId,
			varId: varId,
			qty: qty,
		});

		// Get the spinner element within the button
		var spinner = event.currentTarget.querySelector('.spinner-grow');
		spinner.classList.remove('d-none'); // Show the spinner

		try {
			// Fetch request
			const response = await fetch(`${event.params.url}?${params.toString()}`);

			if (!response.ok) {
				throw new Error('Failed to add product to basket');
			}

			const responseText = await response.json();
			const bodyBasketHolder = responseText.products;

			// Update basket holder content
			basketHolder.innerHTML = bodyBasketHolder;


			event.target.classList.add('blink-border');
			userBasketHolder.reload();
			setTimeout(() => {
				event.target.classList.remove('blink-border');
			}, 1000);

		} catch (error) {
			console.error('Error:', error);
		} finally {
			// Hide the spinner after the request is complete
			spinner.classList.add('d-none'); // Hide the spinner
		}
	}



}
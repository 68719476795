import { Controller } from '@hotwired/stimulus';
import zxcvbn from 'zxcvbn';

const translations = {
	"Use a few words, avoid common phrases": "Uporabite več besed, izogibajte se pogostim frazam.",
	"No need for symbols, digits, or uppercase letters": "Ni potrebe po simbolih, številkah ali velikih črkah.",
	"Add another word or two. Uncommon words are better.": "Dodajte še kakšno besedo. Manj pogoste besede so boljše.",
	"Straight rows of keys are easy to guess": "Ravne vrstice tipk je enostavno uganiti.",
	"Short keyboard patterns are easy to guess": "Kratke vzorce na tipkovnici je enostavno uganiti.",
	"Use a longer keyboard pattern with more turns": "Uporabite daljši vzorec na tipkovnici z več obrati.",
	"Avoid repeated words and characters": "Izogibajte se ponavljajočim besedam in znakom.",
	"Avoid sequences": "Izogibajte se zaporedjem.",
	"Avoid recent years": "Izogibajte se zadnjim letom.",
	"Avoid years that are associated with you": "Izogibajte se letom, ki so povezane z vami.",
	"Avoid dates and years that are associated with you": "Izogibajte se datumom in letom, ki so povezani z vami.",
	"Capitalization doesn't help very much": "Velike začetnice ne pomagajo veliko.",
	"All-uppercase is almost as easy to guess as all-lowercase": "Sami velike črke so skoraj tako enostavne za uganitev kot sami male črke.",
	"Reversed words aren't much harder to guess": "Obrnjene besede niso veliko težje za uganitev.",
	"Predictable substitutions like '@' instead of 'a' don't help very much": "Predvidljive zamenjave, kot je '@' namesto 'a', ne pomagajo veliko."
	// Add more translations as necessary
};

export default class extends Controller {
	static targets = [
		'name',
		'lastname',
		'email',
		'password',
		'agreeTerms',
		'submitButton',
		'meter',
		'feedback'
	];

	connect() {
		this.clearErrors();
		this.showSymfonyErrors();
		this.hidePassword();
		this.updatePasswordStrength();

		this.nameTarget.addEventListener('input', this.validateFields.bind(this));
		this.lastnameTarget.addEventListener('input', this.validateFields.bind(this));
		this.emailTarget.addEventListener('input', this.validateFields.bind(this));
		this.passwordTarget.addEventListener('input', () => {
			this.updatePasswordStrength();
			this.validatePasswords();
		});
		this.agreeTermsTarget.addEventListener('change', this.validateFields.bind(this));
	}

	validate(event) {
		this.clearErrors();
		if (!this.validateFields()) {
			event.preventDefault();
		}
	}

	clearErrors() {
		this.element.querySelectorAll('.invalid-feedback').forEach(error => error.remove());
	}

	showError(message, element) {
		const parentElement = element.closest('.form-outline') || element.parentElement;

		if (parentElement && !parentElement.querySelector('.invalid-feedback')) {
			const error = document.createElement('div');
			error.classList.add('invalid-feedback', 'd-block');
			error.innerText = message;
			parentElement.appendChild(error);
		}
	}

	showSymfonyErrors() {
		const errors = JSON.parse(this.element.getAttribute('data-registration-errors') || '[]');
		errors.forEach(error => {
			const field = this[`${error.field}Target`];
			this.showError(error.message, field);
		});
	}

	togglePasswordVisibility(event) {
		const passwordField = this.passwordTarget;
		const icon = event.target.querySelector('i');

		if (passwordField.type === 'password') {
			passwordField.type = 'text';
			icon.classList.remove('fa-eye');
			icon.classList.add('fa-eye-slash');
		} else {
			passwordField.type = 'password';
			icon.classList.remove('fa-eye-slash');
			icon.classList.add('fa-eye');
		}
	}

	hidePassword() {
		this.passwordTarget.type = 'password';
	}

	updatePasswordStrength() {
		const result = zxcvbn(this.passwordTarget.value);
		const score = result.score * 25;

		this.meterTarget.style.width = `${score}%`;

		// Change progress bar color based on score
		if (score >= 75) {
			this.meterTarget.style.backgroundColor = 'green';
		} else {
			this.meterTarget.style.backgroundColor = 'red';
		}

		// Translate the suggestions
		const translatedSuggestions = result.feedback.suggestions.map(suggestion => translations[suggestion] || suggestion);

		this.feedbackTarget.textContent = translatedSuggestions.join(' ') || 'Geslo je dobro.';
	}

	validateFields() {
		let isValid = true;

		if (this.nameTarget.value.trim() === '') {
			this.showError('Ime je obvezno.', this.nameTarget);
			isValid = false;
		}

		if (this.lastnameTarget.value.trim() === '') {
			this.showError('Priimek je obvezen.', this.lastnameTarget);
			isValid = false;
		}

		if (this.emailTarget.value.trim() === '') {
			this.showError('E-naslov je obvezen.', this.emailTarget);
			isValid = false;
		} else if (!this.validateEmail(this.emailTarget.value.trim())) {
			this.showError('E-naslov ni veljaven.', this.emailTarget);
			isValid = false;
		}

		if (this.passwordTarget.value.trim() === '') {
			this.showError('Geslo je obvezno.', this.passwordTarget);
			isValid = false;
		}

		if (this.passwordTarget.value.trim().length < 12) {
			this.showError('Geslo mora biti dolgo vsaj 12 znakov.', this.passwordTarget);
			isValid = false;
		}

		if (!this.agreeTermsTarget.checked) {
			this.showError('Strinjati se morate s politiko varstva osebnih podatkov.', this.agreeTermsTarget);
			isValid = false;
		}

		this.submitButtonTarget.disabled = !isValid;

		return isValid;
	}

	validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.[^<>()[\]\.,;:\s@"]{2,}))$/i;
		return re.test(String(email).toLowerCase());
	}

	validatePasswords() {
		const password = this.passwordTarget.value;
		const errorElement = document.getElementById('password-error');

		errorElement.style.display = 'none';
		errorElement.innerHTML = '';

		let valid = true;

		const numberPattern = /\d/;
		const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
		const lowerCasePattern = /[a-z]/;
		const upperCasePattern = /[A-Z]/;

		if (password.length < 12) {
			errorElement.innerHTML = 'Geslo mora imeti vsaj 12 znakov.';
			valid = false;
		}

		if (!numberPattern.test(password)) {
			errorElement.innerHTML += (valid ? '' : '<br>') + 'Geslo mora vsebovati vsaj eno številko.';
			valid = false;
		}

		if (!specialCharPattern.test(password)) {
			errorElement.innerHTML += (valid ? '' : '<br>') + 'Geslo mora vsebovati vsaj en poseben znak (!@#$%^&*(),.?":{}|<>).';
			valid = false;
		}

		if (!lowerCasePattern.test(password)) {
			errorElement.innerHTML += (valid ? '' : '<br>') + 'Geslo mora vsebovati vsaj eno malo črko.';
			valid = false;
		}

		if (!upperCasePattern.test(password)) {
			errorElement.innerHTML += (valid ? '' : '<br>') + 'Geslo mora vsebovati vsaj eno veliko črko.';
			valid = false;
		}

		if (!valid) {
			errorElement.style.display = 'block';
		}

		this.submitButtonTarget.disabled = !valid || !this.validateFields();
		this.updateFeedback(valid);
	}

	updateFeedback(passwordValid) {
		const result = zxcvbn(this.passwordTarget.value);
		const translatedSuggestions = result.feedback.suggestions.map(suggestion => translations[suggestion] || suggestion);

		if (passwordValid) {
			translatedSuggestions.push('Geslo je dobro.');
		}

		this.feedbackTarget.textContent = translatedSuggestions.join(' ');
	}
}
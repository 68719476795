// assets/controllers/modal_controller.js
import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
    connect() {
        // Ensure this targets the modal element and shows it
        const modalElement = document.querySelector('#drgrandelmodal');
        if (modalElement) {
            let modalInstance = Modal.getInstance(modalElement);
            if (!modalInstance) {
                modalInstance = new Modal(modalElement);
            }
            modalInstance.show();
        }
    }
}
import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

	connect() {

		const topMenus = document.querySelectorAll('.topmenu');
		const topMenuHolder = document.getElementById('topmenuholder');

		topMenus.forEach((topMenu, index) => {
			topMenu.addEventListener("click", () => {
				const eleId=topMenu.dataset.id;
				this.show(eleId);
			})
		})

		// topMenuHolder.addEventListener("mouseleave", () => {
		// 	this.hide()
		// })
	}

	show(eleId){
		const blockId= eleId;
		const currSubBox = document.getElementById('sub'+blockId);

		const subMenus = document.querySelectorAll('.slidedownmenu');

		subMenus.forEach((element) => {
			if (! element.classList.contains('d-none')){
				element.classList.add('d-none');
			}
		});

		currSubBox.classList.remove('d-none');
	}

	hide(event){
		event.preventDefault();
		const subMenus = document.querySelectorAll('.slidedownmenu');

		subMenus.forEach((element) => {
			if (! element.classList.contains('d-none')){
				element.classList.add('d-none');
			}
		});
	}
}

// assets/controllers/index.js
import { Application } from '@hotwired/stimulus';
import TomSelectController from './tom_select_controller';
import CkeditorController from './ckeditor_controller';

const application = Application.start();

application.register('tom-select', TomSelectController);
application.register('ckeditor', CkeditorController);


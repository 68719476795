// assets/controllers/trustlogo_controller.js

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static values = {
		logoUrl: String,
		logoType: String,
		logoPosition: String,
	}

	connect() {
		this.loadExternalScript();
	}

	loadExternalScript() {
		const scriptUrl = (window.location.protocol === "https:")
			? "https://secure.trust-provider.com/trustlogo/javascript/trustlogo.js"
			: "http://www.trustlogo.com/trustlogo/javascript/trustlogo.js";

		if (!this.isScriptLoaded(scriptUrl)) {
			const script = document.createElement('script');
			script.src = scriptUrl;
			script.async = true;
			script.onload = this.applyTrustLogo.bind(this);
			script.onerror = () => {
				console.error('Failed to load the TrustLogo script.');
				// Handle the error or provide a fallback mechanism
			};
			document.head.appendChild(script);
		} else {
			this.applyTrustLogo();
		}
	}

	isScriptLoaded(url) {
		return Array.from(document.getElementsByTagName('script')).some(script => script.src === url);
	}

	applyTrustLogo() {
		if (window.TrustLogo) {
			window.TrustLogo(this.logoUrlValue, this.logoTypeValue, this.logoPositionValue);
		}
	}
}
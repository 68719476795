import { Controller } from '@hotwired/stimulus';

/**
 * Stimulus controller for order form handling
 */
export default class extends Controller {
	static targets = ['bbusiness', 'dbusiness'];
	connect() {
		// Called when the controller is connected to the DOM
		this.toggleSection('b');
		this.toggleSection('d');
	}

	toggleVisibility(element, show) {
		const classList = element.classList;
		classList.toggle('d-none', show);
	}

	changeDeliveryType(event) {
		const shippmentBox = document.getElementById('shippmentbox');
		const payOnDelivery = document.getElementById('payonpick');
		const isSelfPick = event.params.type === 'selfpick';

		this.toggleVisibility(shippmentBox, isSelfPick);
		payOnDelivery.disabled = isSelfPick;
		if (isSelfPick) {
			payOnDelivery.checked = true;
			payOnDelivery.disabled = false;
			const turboFrame = document.getElementById('order-summary');
			turboFrame.removeAttribute('complete');
			const newSrc = `/order-summary?selfpick=true`;
			turboFrame.setAttribute('src', newSrc);
			turboFrame.reload();
		}else{
			payOnDelivery.checked = false;
			payOnDelivery.disabled = true;
			const turboFrame = document.getElementById('order-summary');
			turboFrame.removeAttribute('complete');
			const newSrc = `/order-summary?selfpick=false`;
			turboFrame.setAttribute('src', newSrc);
			turboFrame.reload();
		}
	}

	toggleBusinessFields(checked, isDelivery) {
		const businessBoxId = isDelivery ? 'dbusiness' : 'bbusiness';
		const businessBox = document.getElementById(businessBoxId);
		this.toggleVisibility(businessBox, checked);
	}

	toggleShippmentHolder(event) {
		const shippmentHolder = document.getElementById('shippmentholder');
		this.toggleVisibility(shippmentHolder, event.currentTarget.checked);
	}

	async choosepaymentaddress(event) {
		await this.updateAddressFields(event.currentTarget.value, event.params.url, false);
	}

	async choosedeliveryaddress(event) {
		await this.updateAddressFields(event.currentTarget.value, event.params.url, true);
	}

	async updateAddressFields(id, url, isDelivery) {
		const prefix = isDelivery ? 'd' : 'b';
		const fieldIds = ['name', 'lastname', 'email', 'phone', 'address', 'postnumber', 'city', 'country'];
		const businessFields = ['isBusiness', 'ddv', 'companyname'];

		this.clearErrors();
		if (id === '0') {
			this.clearFields(isDelivery);
			this.enableFields(true, isDelivery);
			this.toggleBusinessFields(false, isDelivery);
		} else {
			try {
				const address = await this.getAddressById(`${url}?id=${id}`);
				fieldIds.forEach(field => {
					document.getElementById(`${prefix}${field}`).value = address[field];
					document.getElementById(`${prefix}${field}`).disabled = false;
				});

				const isBusiness = address.isBusiness;

				document.getElementById(`${prefix}isbusiness`).checked = isBusiness;
				businessFields.forEach(field => {
					if(field != 'isBusiness' ){
						document.getElementById(`${prefix}${field}`).value = isBusiness ? address[field] : '';
					}

				});
				this.toggleBusinessFields(isBusiness, isDelivery);
			} catch (error) {
				console.log(error);
				this.displayError(`error-${prefix}address`, 'Prišlo je do napake pri nalaganju naslova.');
			}
			//this.enableFields(false, isDelivery);
		}
	}

	async getAddressById(url) {
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error('Serverjev odgovor ni bil v redu');
			}
			return response.json();
		} catch (error) {
			console.error('Napaka pri nalaganju:', error);
			throw error;
		}
	}

	enableSubmitButton(event) {
		const submitButton = document.getElementById('submitform');
		submitButton.disabled = !event.currentTarget.checked;
	}

	submitorder(event) {
		event.preventDefault();
		this.clearErrors();
		this.showLoader();

		if (this.validateForm()) { //TODO: remove validation
		//if (true) {
			// Get the form element and URL from the data attribute
			const form = document.getElementById('order-form');
			const formData = new FormData(form);
			const url = form.getAttribute('action');

			// Send the form data via fetch
			fetch(url, {
				method: 'POST',
				body: formData,
				headers: {
					'Accept': 'application/json',
				},
			})
				.then(response => response.json())
				.then(data => {
					if (data.success) {
						window.location.href = data.redirectUrl;
						form.reset();
					} else {
						this.displayError('form-errors', data.message || 'Prišlo je do napake pri pošiljanju.');
						this.hideLoader();
					}
				})
				.catch(error => {
					console.error('Napaka pri pošiljanju:', error);
					this.displayError('form-errors', 'Prišlo je do napake pri pošiljanju.');
				});
		}else {
			this.hideLoader();
		}

	}

	showLoader() {
		const loader = document.getElementById('fullScreenLoader');
		loader.classList.remove('d-none');
		loader.classList.add('d-flex');
	}

	hideLoader() {
		const loader = document.getElementById('fullScreenLoader');
		loader.classList.remove('d-flex');
		loader.classList.add('d-none');
	}

	validateForm() {
		const isEqualBillingChecked = document.getElementById('equalbilling').checked;
		const deliveryMethod = document.querySelector('input[name="deliverymethod"]:checked');
		const paymentMethod = document.querySelector('input[name="paymentmethod"]:checked');

		// Check if delivery method and payment method are selected
		let isValid = true;

		if (!deliveryMethod) {
			this.displayError('error-deliverymethod', 'Prosimo, izberite način dostave.');
			isValid = false;
		}

		if (!paymentMethod) {
			this.displayError('error-paymentmethod', 'Prosimo, izberite način plačila.');
			isValid = false;
		}

		const skipDFields = isEqualBillingChecked || (deliveryMethod && deliveryMethod.value === 'selfpick');

		const requiredFields = [
			...['b'].flatMap(prefix => [
				`${prefix}name`, `${prefix}lastname`, `${prefix}email`,
				`${prefix}phone`, `${prefix}address`, `${prefix}postnumber`,
				`${prefix}city`, `${prefix}country`
			]),
			...(!skipDFields ? ['d'].flatMap(prefix => [
				`${prefix}name`, `${prefix}lastname`, `${prefix}email`,
				`${prefix}phone`, `${prefix}address`, `${prefix}postnumber`,
				`${prefix}city`, `${prefix}country`
			]) : [])
		];

		requiredFields.forEach(id => {
			const field = document.getElementById(id);
			if (field && !field.value.trim()) {
				this.displayError(`error-${id}`, 'To polje je obvezno.');
				isValid = false;
			}
		});

		return isValid;
	}

	clearErrors() {
		document.querySelectorAll('[id^="error-"]').forEach(el => el.textContent = '');
	}

	displayError(id, message) {
		const errorElement = document.getElementById(id);
		if (errorElement) {
			errorElement.textContent = message;
		}
	}

	clearFields(isDelivery = false) {
		const prefix = isDelivery ? 'd' : 'b';
		['name', 'lastname', 'email', 'phone', 'address', 'postnumber', 'city', 'country'].forEach(field => {
			document.getElementById(`${prefix}${field}`).value = '';
		});
	}

	enableFields(enable, isDelivery = false) {
		const prefix = isDelivery ? 'd' : 'b';
		['name', 'lastname', 'email', 'phone', 'address', 'postnumber', 'city', 'country', 'isBusiness', 'ddv', 'companyname']
			.forEach(field => {
				const element = document.getElementById(`${prefix}${field}`);
				if (element) {
					element.disabled = !enable;
				}
			});
	}

	toggle(event) {
		const prefix = event.target.id.charAt(0); // Get the prefix (b or d)
		this.toggleSection(prefix);
	}

	toggleSection(prefix) {
		const checkbox = document.getElementById(`${prefix}isbusiness`);
		const section = this.targets.find(`${prefix}business`);

		if (checkbox.checked) {
			section.classList.remove('d-none');
		} else {
			section.classList.add('d-none');
		}
	}
}

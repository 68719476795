import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		const isBusiness = document.getElementById('add_address_to_user_isBusiness');
		const businessBox = document.getElementById('businessbox');


		if (!isBusiness || isBusiness.checked === false) {
			businessBox.classList.add('d-none');
		}

		isBusiness.addEventListener('change', (event) => {

			if (event.currentTarget.checked) {
				businessBox.classList.remove('d-none');
			} else {
				businessBox.classList.add('d-none');
			}
		})

	}
}

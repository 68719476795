import { Controller } from '@hotwired/stimulus';
//import * as bootstrap from 'bootstrap'; // Ensure Bootstrap is imported for handling modals
import Modal from "bootstrap/js/src/modal";

export default class extends Controller {
	static targets = ['modal', 'modalBody', 'addressRow'];

	connect() {
		this.modalElement = this.modalTarget; // The modal element
		this.modalBodyElement = this.modalBodyTarget; // The body where the form will be injected
	}

	// Load the form for editing into the modal
	async edit(event) {
		event.preventDefault();
		const addressId = event.currentTarget.dataset.id;
		const formUrl = `/user/address/edit/${addressId}`;

		// Fetch the form and insert it into the modal body
		const response = await fetch(formUrl);
		const html = await response.text();
		this.modalBodyElement.innerHTML = html;

		// Show the modal using Bootstrap's Modal instance
		const modal = new Modal(this.modalElement);
		modal.show();
	}

	// Handle form submission and update the Turbo frame row
	// Handle form submission and update the Turbo frame row
	async submitForm(event) {
		event.preventDefault();
		const form = event.target;

		const addressId = form.querySelector('input[name="address_id"]').value;

		const response = await fetch(form.action, {
			method: form.method,
			body: new FormData(form),
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
			},
		});

		if (response.ok) {
			// Hide the modal after successful submission
			const modalInstance = Modal.getInstance(this.modalTarget);
			modalInstance.hide();

			// Get the response containing the updated fields wrapped in turbo-frames
			const updatedFieldsHtml = await response.text();

			// Parse the response HTML
			const parser = new DOMParser();
			const doc = parser.parseFromString(updatedFieldsHtml, 'text/html');

			// Update the individual turbo frames inside the row
			const updatedNameFrame = doc.getElementById(`name_${addressId}`);
			const updatedAddressFrame = doc.getElementById(`address_${addressId}`);
			const updatedBusinessFrame = doc.getElementById(`business_${addressId}`);

			// Replace the existing turbo frames with the new ones
			if (updatedNameFrame && updatedAddressFrame) {
				document.getElementById(`name_${addressId}`).outerHTML = updatedNameFrame.outerHTML;
				document.getElementById(`address_${addressId}`).outerHTML = updatedAddressFrame.outerHTML;
				document.getElementById(`business_${addressId}`).outerHTML = updatedBusinessFrame.outerHTML;
			}
		} else {
			// Handle form errors and re-display them in the modal
			const html = await response.text();
			this.modalBodyTarget.innerHTML = html;
		}
	}


	// Delete an address
	async delete(event) {
		event.preventDefault();
		const form = event.target.closest('form');
		const addressId = form.action.split('/').pop();

		const response = await fetch(form.action, {
			method: 'POST',
			body: new FormData(form),
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
			},
		});

		if (response.ok) {
			// Remove the address row from the table
			document.getElementById(`addressrow_${addressId}`).remove();
		} else {
			alert('Failed to delete the address.');
		}
	}
}

import controller_0 from '@leapt/slug-type-bundle/dist/controller.js';
import '@leapt/slug-type-bundle/dist/style.css';
import controller_1 from '@symfony/ux-autocomplete/dist/controller.js';
import 'tom-select/dist/css/tom-select.default.css';
import controller_2 from '@symfony/ux-turbo/dist/turbo_controller.js';
import controller_3 from '@symfony/ux-turbo/dist/turbo_stream_controller.js';
export default {
  'leapt--slug-type-bundle--slug': controller_0,
  'symfony--ux-autocomplete--autocomplete': controller_1,
  'symfony--ux-turbo--turbo-core': controller_2,
  'symfony--ux-turbo--mercure-turbo-stream': controller_3,
};
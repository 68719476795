// assets/controllers/newsletter_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['message'];

	async submit(event) {
		event.preventDefault(); // Prevent the default form submission

		const formData = new FormData(event.target);

		try {
			const response = await fetch(event.target.action, {
				method: 'POST',
				body: formData,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			});

			const result = await response.json();

			// Log the result to see the API response structure
			console.log('API Response:', result);

			// If the submission is successful, show a success message and add the blink effect
			if (result.success && result.data && result.data.success) {
				this.messageTarget.innerHTML = '<p class="text-primary mt-5 " style="font-size: 16px;">Na vaš e-naslov je bilo poslano sporočilo. Prosimo, da vašo prijavo potrdite s klikom na povezavo v e-sporočilu.</p>';
				this.messageTarget.classList.add('blink-success');

				// Optionally, remove the blink effect after the animation ends (3 seconds in this case)
				setTimeout(() => {
					this.messageTarget.classList.remove('blink-success');
				}, 3000);
			} else {
				const errorMessage = result.message || 'An error occurred. Please try again.';
				this.messageTarget.innerHTML = `<p class="text-danger">Error: ${errorMessage}</p>`;
			}
		} catch (error) {
			this.messageTarget.innerHTML = '<p class="text-danger">Nekaj je šlo narobe. Poskusite znova kasneje.</p>';
			console.error('Fetch error:', error);
		}
	}
}

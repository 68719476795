import { Controller } from '@hotwired/stimulus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class extends Controller {
	connect() {
		ClassicEditor
			.create(this.element, {
				toolbar: [
					'heading', '|',
					'bold', 'italic', 'link', '|',
					'bulletedList', 'numberedList', 'blockQuote', '|',
					'undo', 'redo'
				]
			})
			.catch(error => {
				console.error('There was a problem initializing CKEditor 5:', error);
			});
	}
}